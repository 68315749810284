import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";

import { CircularProgress, Col, Content } from '@gower/react-ui/components';
import { Page404 } from '@gower/react-ui/template/page404';
import { RoutesEnum } from './routes';
import CredentialProvider from 'app/credential-provider';
import { Layout } from '../layout';
import { LayoutEmpty } from '../layout-empty';
import { useCheckSession } from 'features/hooks/useCheckSession';
// AUTH
import { LoginView } from 'views/login';
import { ForgotPasswordView } from 'views/forgot-password';
import { ActivateUserView } from 'views/activate-user';
// VIEWS
import { HomeView } from 'views/home';
import { UsersView } from 'views/users';
import { ProfileView } from 'views/profile';
import { SecureCodesView } from 'views/secure-codes/secure-codes';
import { EditUserView } from 'views/edit-user';
import { UsersCreateView } from 'views/users-create';
import { UsersEditView } from 'views/users-edit';
import { ImportUsersView } from 'views/import-users';
import { ChangePasswordView } from 'views/change-password';

import { PayoutsView } from 'views/payouts';
import { CalculatorView } from 'views/calculator';
import { PayoutInvoicesView } from 'views/payout-invoices';

export const Router = () => {

    const { check } = useCheckSession();
    const [loading, setLoading] = React.useState( true );

    React.useEffect( () => {

        ( async () => {
            await check();
            setLoading( false );
        } )();

    }, [] );

    if ( loading )
        return <Loading />;

    return <>
        <BrowserRouter>
            <Routes>
                <Route path={RoutesEnum.Base} element={<RequireAuth Element={Layout} />} >
                    <Route path={RoutesEnum.Home} element={<HomeView/>} />
                    <Route path={RoutesEnum.Payouts} element={<PayoutsView />} />
                    <Route path={RoutesEnum.Calculator} element={<CalculatorView />} />
                    <Route path={RoutesEnum.PayoutInvoices} element={<PayoutInvoicesView />} />
                    <Route path={RoutesEnum.Users} element={<UsersView />} />
                    <Route path={RoutesEnum.UsersImport} element={<ImportUsersView />} />
                    <Route path={RoutesEnum.UsersCreate} element={<UsersCreateView />} />
                    <Route path={`${ RoutesEnum.UsersEdit }/:id`} element={<UsersEditView />} />
                    <Route path={RoutesEnum.Profile} element={<ProfileView />} />
                    <Route path={RoutesEnum.EditUser} element={<EditUserView />} />
                    <Route path={RoutesEnum.ChangePassword} element={<ChangePasswordView />} />
                    <Route path={RoutesEnum.SecureCodes} element={<SecureCodesView />} />
                    <Route path='*' element={<NotFoundPage />} />
                </Route>
                <Route path={RoutesEnum.BaseAuth} element={<RequireAuth reverse Element={LayoutEmpty} />} >
                    <Route index element={<LoginView />} />
                    <Route path={RoutesEnum.Login} element={<LoginView />} />
                    <Route path={RoutesEnum.ForgotPassword} element={<ForgotPasswordView />} />
                    <Route path={RoutesEnum.ActivateUser} element={<ActivateUserView />} />
                    <Route path='*' element={<LoginView />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </>;
};


type RequireAuthProps = {
    reverse?: boolean;
    Element?: any;
};
const RequireAuth: React.FC<RequireAuthProps> = ( { Element, reverse } ) => {
    const isAuthenticated = CredentialProvider.isAuthenticated;
    const location = useLocation();

    if ( !reverse && !isAuthenticated )
        return <Navigate to={RoutesEnum.Login} state={{ from: location }} replace />;

    if ( reverse && isAuthenticated )
        return <Navigate to={RoutesEnum.Home} state={{ from: location }} replace />;

    return <Element />;
};

const NotFoundPage: React.FC = () => {
    return (
        <Content fullWidth fullHeight justifyCenter>
            <Col center>
                <Page404 />
            </Col>
        </Content>
    );
};

const Loading: React.FC = () => {
    return (
        <Content
            height="100vh"
            width="100vw"
            justifyCenter
        >
            <Col center textColor="primary">
                <CircularProgress
                    color="inherit"
                    size={36}
                    thickness={5}
                />
            </Col>
        </Content>
    );
};