import { Message, MessageSearchService } from '@gower/apsc-sdk/services';
import { MutableStore, expose } from '@gower/react-ui/store';
import CredentialProvider from '../../app/credential-provider';
import { MessageBox } from '@gower/react-ui/message-box';
import { Wait } from '@gower/utils';

export class HomeStore extends MutableStore {

    public Messages: Message[] = [];
    private service = new MessageSearchService;

    protected onAfterRender ( props?: any ): void {
        this.search();
    }

    async search () {
        const license = CredentialProvider.getUserLicense();
        this.Messages = [];
        if ( license ) {
            const data = await this.service.find( { license } as any );
            this.Messages = data.results;
        }
    }

    async read ( message: Message ) {

        const result = await this.service.read( message );

        if ( !result ) {
            await MessageBox.Error( {
                content: this.service.LastErrors
            } );
        } else {
            message.Readed = true;
        }

    }

}

export const { provider, useStore } = expose( HomeStore );