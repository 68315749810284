import { Button, Col, Content, Div, Label, Row, Spacer, Title } from '@gower/react-ui/components';
import { AppLogo } from 'lib/components/logo';
import { PropsWithChildren } from 'react';

import { DrawerWrapper } from '@gower/react-ui/template/drawer';
import { ProfileMenu } from '@gower/react-ui/template/profile-menu';
import { useSession } from 'features/hooks/useSession';
import { useLogout } from 'features/hooks/useLogout';
import { DrawerItems, RoutesEnum } from 'app/routes';

export type HeaderProps = {

};
export const Header: React.FC<PropsWithChildren<HeaderProps>> = ( { children } ) => {
    const { user } = useSession();
    const { logout } = useLogout();

    return <>
        <Content color='white' box-shadow-1 height={64} noWrap zIndex={10}>
            <Col>
                <DrawerWrapper
                    items={DrawerItems}
                    drawerFooter={
                        <Row paddingX={20} paddingY={5}>
                            <Col >
                                <Div block small textGrey>
                                    <Label p>Liquidaciones</Label>
                                    <Label p>v{window.env?.REACT_APP_VERSION} ({window.env?.REACT_APP_STAGE})</Label>
                                </Div>
                            </Col>
                        </Row>
                    }
                />
            </Col>
            <Col center>
                <Button pa-1 borderRadiusAll={5} noneTheme text reactLink='/'>
                    <Row justifyCenter fullWidth noWrap noScroll>
                        <Col center pr-1>
                            <AppLogo size={54} />
                        </Col>
                        <Col center >
                            <Div block textLeft>
                                <Title xsmall ma-0 bold letterSpacing="wide" textColor="blue-7" label="ASOCIACION PROFESIONALES" />
                                <Title xsmall ma-0 bold letterSpacing="wide" textColor="blue-7" label="DE LA SALUD DE CAMPANA" />
                            </Div>
                        </Col >
                    </Row>
                </Button>
            </Col>
            <Spacer noScroll height="100%" px-1>
                {children}
            </Spacer>



            <Col width="5%" mobileHidden xsHidden />
            <Col fullHeight>
                <ProfileMenu
                    user={user.getJson() as any}
                    logout={logout}
                    myProfileLink={RoutesEnum.Profile}
                />
            </Col>
        </Content>

    </>;
};

