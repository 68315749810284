import dayjs from 'dayjs';
import { Col, Content, Div, Label, Row, Spacer, Button, Autocomplete2, Alert, Divider, Colors, TextInput2, Tooltip, TextDateInput2 } from '@gower/react-ui/components';
import { useStore } from '../store';
import { DataGrid, DataGridColumn } from '@gower/react-ui/template/data-grid';
import { useUserLicense } from 'features/hooks/useUserLincese';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';
import { Payout } from '@gower/apsc-sdk/payouts';

export const CalculatorDesktop = () => {

    const store = useStore();
    const { license } = useUserLicense();

    return <>
        <Content columns color="surface-0" scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Valores de Prestaciones</Label>
                            <Label xsmall p textGrey>Calcule y valorize prestaciones.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center>
                        <Alert
                            fullWidth
                            title="Estimado usuario."
                            content="Ingresé la información requerida y pulse Calcular"
                        />
                    </Col>
                </Row>
            </Col>
            <Col padding={10} >
                <Content columns justifyCenter gap={10}>
                    <Col center white borderRadius={5} fullWidth maxWidth={800} padding={20}>
                        <Row columns>
                            <Col height={30} />
                            <Col gutter>
                                <TextInput2
                                    label="Matrícula"
                                    value={license}
                                    readOnly
                                    contentRight={(
                                        <Col center paddingX={5} paddingTop={5}>
                                            <Tooltip title="La matrícula no puede editarse">
                                                <MdOutlineHelpOutline size={20} color={Colors['primary']} />
                                            </Tooltip>
                                        </Col>
                                    )}
                                />
                            </Col>
                            <Col gutter>
                                <TextDateInput2
                                    label="Fecha de Vigencia"
                                    value={store.date}
                                    onChangeValue={( v ) => store.date = v}
                                    contentRight={(
                                        <Col center>
                                            <Button text bold label="Hoy" onClick={() => store.date = dayjs()} />
                                        </Col>
                                    )}
                                />
                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    submitOnEnter
                                    disabled={!store.Status.isPending}
                                    label="Prestación"
                                    clearable
                                    placeholder="Escriba la descripción o código de la prestación a consultar"
                                    optionsAsync={( q ) => store.findHealthServices( q )}
                                    value={store.healthService}
                                    onChangeValue={v => store.healthService = v}
                                    displayKey={( { item, format } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold html={format( item.Id, { color: Colors['primary'] } )} />
                                            </Col>
                                            <Col gutter>
                                                <Label html={format( item.Description )} />
                                            </Col>
                                        </Row>
                                    )}
                                    selectedKey={( { item } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold label={item.Id} />
                                            </Col>
                                            <Col gutter>
                                                <Label label={item.Description} />
                                            </Col>
                                        </Row>
                                    )}
                                />

                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    select
                                    disabled={!store.Status.isPending}
                                    label="Actuación"
                                    placeholder="Seleccione su actuación"
                                    options={Payout.VO.Role}
                                    value={store.role}
                                    onChangeValue={v => store.role = v}
                                    displayKey="Description"
                                />
                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    submitOnEnter
                                    disabled={!store.Status.isPending}
                                    label="Obra social"
                                    clearable
                                    placeholder="Seleccione la Obra Social a consultar"
                                    hint="Seleccione una Obra Social o dejelo en blanco para cotizar todas."
                                    suffix={!store.healthEntity ? "(Vacio todas)" : ""}
                                    optionsAsync={( q ) => store.findAllHealthEntities( q )}
                                    filterKey={["Description", "Id"]}
                                    value={store.healthEntity}
                                    onChangeValue={v => store.healthEntity = v}
                                    displayKey={( { item, format } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold html={format( item.Id, { color: Colors['primary'] } )} />
                                            </Col>
                                            <Col gutter>
                                                <Label html={format( item.Description )} />
                                            </Col>
                                        </Row>
                                    )}
                                    selectedKey={( { item } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold label={item.Id} />
                                            </Col>
                                            <Col gutter>
                                                <Label label={item.Description} />
                                            </Col>
                                        </Row>
                                    )}
                                />

                            </Col>
                            <Col height={30} />
                            <Divider marginY={12} show={store.Status.isOk} />

                            <Col gutter show={store.Status.isOk}>
                                <Div>
                                    <Label p xxsmall light label="Resultado de la valorización:" />
                                </Div>
                            </Col>

                            {( store.payoutCalculatorResult?.length > 0 ) &&
                                <Col gutter show={store.Status.isOk}>
                                    <Row color="surface-0" border borderColor="surface-4" rounded padding={10}>
                                        <Col cols={6} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Matrícula" />
                                                <Label p bold xnormal label={license} />
                                            </Div>
                                        </Col>
                                        <Col cols={6} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Actuación" />
                                                <Label p bold xnormal label={store.role?.Description} />
                                            </Div>
                                        </Col>
                                        <Col cols={12} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Prestación" />
                                                <Label p bold xnormal label={store.healthService?.Description} />
                                            </Div>
                                        </Col>
                                        <Col cols={12} gutter minHeight={200}>
                                            <DataGrid
                                                data={store.payoutCalculatorResult}
                                                showSearch
                                                autoSorting
                                                searchPlaceholder="Buscar Obra Social"
                                                searchFn={( query, rows ) => {
                                                    return rows.filter( row => (
                                                        String( row.HealthEntity?.Id ).toLowerCase().includes( query.toLowerCase() ) ||
                                                        String( row.HealthEntity?.Description ).toLowerCase().includes( query.toLowerCase() )
                                                    ) );
                                                }}
                                            >
                                                <DataGridColumn
                                                    type="CUSTOM"
                                                    title="Obra Social"
                                                    field="HealthEntity.Id"
                                                    sortable
                                                    template={( { data } ) => (
                                                        <Div>
                                                            <Label bold gutter label={data.HealthEntity?.Id} />
                                                            <Label label={data.HealthEntity?.Description} />
                                                        </Div>
                                                    )} />
                                                <DataGridColumn title="Gastos" sortable type="CURRENCY" field="Expenses" />
                                                <DataGridColumn title="Honorarios" sortable type="CURRENCY" cellProps={{ bold: true }} field="ProfessionalFee" />
                                                <DataGridColumn title="Total" sortable type="CURRENCY" cellProps={{ bold: true }} field="Total" />
                                            </DataGrid>
                                        </Col>

                                    </Row>
                                </Col>}

                            <Col gutter>
                                <Row>
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button width={120} text label="Restablecer" disabled={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                    <Spacer />
                                    <Col gutter>
                                        <Button width={140} primary normal letterSpacing="wide" label="Descargar" loading={store.StatusDownload.isLoading} disabled={!store.canCalculate || store.Status.isLoading} onClick={() => store.download()} />
                                    </Col>
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button width={140} primary normal letterSpacing="wide" label="Calcular" loading={store.Status.isLoading} disabled={!store.canCalculate} onClick={() => store.calc()} />
                                    </Col>
                                    <Col gutter show={store.Status.isOk}>
                                        <Button width={140} primary letterSpacing="wide" label="Restablecer" loading={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col center white borderRadius={5} fullWidth maxWidth={800} padding={20} disabled={!store.history.length}>
                        <Row columns>
                            <Col gutter>
                                <Div>
                                    <Label p xxsmall light label="Búsquedas anteriores:" />
                                </Div>
                            </Col>

                            <Col gutter minHeight={200}>
                                <DataGrid
                                    data={store.history}
                                >
                                    <DataGridColumn title="Fecha" type="DATE" field="Date" />
                                    <DataGridColumn title="Obra Social" field="HealthEntity.Description" />
                                    <DataGridColumn title="Prestación" field="HealthService.Description" />
                                    <DataGridColumn title="Actuación" field="Role.Description" />
                                    <DataGridColumn title="Gastos" type="CURRENCY" field="Expenses" />
                                    <DataGridColumn title="Honorarios" type="CURRENCY" cellProps={{ bold: true }} field="ProfessionalFee" />
                                </DataGrid>
                            </Col>

                        </Row>
                    </Col>
                </Content>
            </Col>
        </Content>
    </>;
};