import { MessageBox } from '@gower/react-ui/message-box';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { ErrorManager } from '@gower/react-ui/utils';
import { User } from '@gower/shared-sdk/core/users';

export class UserEditStoreSubstore extends MutableStore {

    private _store: UserEditStore;
    protected get MainStore () { return this._store; }
    protected get Item () { return this.MainStore.Item; }

    ___internal___mounted ( props ) {
        this._store = props.store;
    }
}

class UserEditStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'loading' );
    public Item: User = null;

    async onReceiveProps ( props ) {

        if ( !this.Item ) {

            this.Status.setLoading();

            const userId = props?.routerParams?.id;
            if ( userId ) {
                const user = await User.findById( userId );
                if ( user ) {
                    this.Item = user;
                    this.Status.setOk();
                }
            }

            if ( !this.Item ) {
                this.Status.setEmpty();
                MessageBox.Accept( 'Usuario no existe' );
            }
        }
    }
}

export const { useStore, provider } = expose( UserEditStore );