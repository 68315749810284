import Event from 'typescript.events';

export function adminOnly () {
    return CredentialProvider.isAdministrator();
}

export class CredentialsProvider extends Event {
    private authenticated: boolean = false;
    public get isAuthenticated () { return this.checkAuthenticated(); }
    public get SecureStore () { return window.localStorage; }

    private checkAuthenticated () {
        this.authenticated = !!this.getToken() && !!this.getSessionId();
        this.emit( 'authentication', { ready: this.authenticated } );

        return this.authenticated;
    }

    public getSessionId () { return this.SecureStore.getItem( 'Authorization.SessionId' ); };
    public getUserId () { return this.SecureStore.getItem( 'Authorization.UserId' ); };
    public getAccountId () { return this.SecureStore.getItem( 'Authorization.AccountId' ); };
    public getToken () { return this.SecureStore.getItem( 'Authorization.Token' ); };
    public getUser () {
        const useStr = this.SecureStore.getItem( 'Authorization.User' );
        if ( useStr ) {
            try {
                return JSON.parse( useStr ) as Session['user'];
            } catch ( error ) {

            }
        }

        this.clear();
    };

    public getUserLicense (): string {
        const user = this.getUser();
        if ( user && Array.isArray( user['metadata']?.['licenses'] ) ) {
            return user['metadata']?.['licenses'][0];
        }
    };

    async setSession ( session: Session ) {
        await this.clear();
        this.SecureStore.setItem( 'Authorization.SessionId', session.id );
        this.SecureStore.setItem( 'Authorization.UserId', session.user.id );
        this.SecureStore.setItem( 'Authorization.AccountId', String( session.user.account ) );
        this.SecureStore.setItem( 'Authorization.Token', session.token );
        this.SecureStore.setItem( 'Authorization.User', JSON.stringify( session.user ) );
    }

    async updateUser ( user: Session['user'] ) {
        this.SecureStore.setItem( 'Authorization.User', JSON.stringify( user ) );
    }

    async restore () {
        return this.checkAuthenticated();
    }

    async clear () {
        this.SecureStore.removeItem( 'Authorization.SessionId' );
        this.SecureStore.removeItem( 'Authorization.UserId' );
        this.SecureStore.removeItem( 'Authorization.AccountId' );
        this.SecureStore.removeItem( 'Authorization.Token' );
        this.SecureStore.removeItem( 'Authorization.User' );

        return await this.checkAuthenticated();

    }
    
    public isAdministrator () {
        const user = this.getUser();
        if ( user?.group === 'administrator' )
            return true;
        return false;
    }
}

const CredentialProvider = new CredentialsProvider();

export default CredentialProvider;

export type Session = {
    "id": string,
    "created_at": string,
    "expire_date": string,
    "status": string,
    "mode": string,
    "user": {
        "account": number,
        "id": string,
        "nickname": string,
        "name": string,
        "lastname": string,
        "email": string,
        "telephone": string,
        "cellphone": string,
        "group": string,
        "permissions": string[],
        "config": {};
    },
    "token": string;
};