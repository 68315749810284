import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete } from '@gower/react-ui/components';
import { useBoolean, ViewStatus } from '@gower/react-ui/hooks';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose } from '@gower/react-ui/store';
import { ErrorManager } from '@gower/react-ui/utils';
import { UserEditStoreSubstore } from '../store';


class EditPasswordStore extends UserEditStoreSubstore {

    public readonly Status = ViewStatus.new( 'loading' );
    public readonly ErrorManager = ErrorManager.new( {
        autoClearTimeOut: 4000,
        fields: ['Password']
    } );

    public Password1 = '';
    public Password2 = '';

    onAfterRender () {
        if ( this.Item )
            this.Status.setOk();
    }

    reset () {
        this.Password1 = '';
        this.Password2 = '';
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.Password1 || this.Password1.length < 6 ) this.ErrorManager.setPassword( 'Completá este campo' );
        else if ( this.Password1 != this.Password2 ) this.ErrorManager.setPassword( 'La contraseña no coincide' );

        return !this.ErrorManager.hasErrors();
    }

    public async save () {

        if ( this.checkErrors() ) {

            this.Status.setSaving();
            this.Item.Password = this.Password1;

            const result = await this.Item.save();
            if ( result?.result ) {
                
                this.reset();
                Notifications.Saved();

            } else {
                await MessageBox.Error( {
                    content: result.errorList
                } );
            }

            this.Status.setOk();

        }
    }
}

const { useStore, provider } = expose( EditPasswordStore );


export const UsersEditPassword = provider( () => {

    const store = useStore();

    return <>

        <Row paddingBottom={10}>
            <Col gutter center cols={12}>
                <Label p bold normal>Actualizar la contraseña</Label>
            </Col>
            <Col cols={12}>
                <Divider />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Contraseña</Label>
                    <Label p textGrey xsmall>Editar la contraseña del usuario</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    password
                    autoComplete="new-password"
                    value={store.Password1}
                    onChangeText={t => store.Password1 = t}
                    placeholder="Password"
                    readOnly={!store.Status.isOk}
                    error={store.ErrorManager.getPassword()}
                    hint="Minimo 6 carácteres."
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Confirmar contraseña</Label>
                    <Label p textGrey xsmall>Repite la contraseña del usuario</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    password
                    autoComplete="new-password"
                    value={store.Password2}
                    onChangeText={t => store.Password2 = t}
                    placeholder="Password"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>


        <Row justifyEnd >
            <Col gutter hidden>
                <Button
                    actionTheme
                    disabled={!store.Status.isOk}
                    label="Cancelar"
                />
            </Col>
            <Col gutter >
                <Button
                    primary
                    disabled={store.Status.isLoading}
                    loading={store.Status.isSaving}
                    onClick={() => store.save()}
                    label="Actualizar contraseña"
                />
            </Col>
        </Row>

    </>;
} );

