import React from 'react';
import { Alert, Button, Checkbox, Chip, Col, Colors, Content, Div, Divider, Input, Label, Row, SearchInput, Spacer, Tooltip } from '@gower/react-ui/components';
import { RoutesEnum } from 'app/routes';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import { DataGrid, DataGridColumn, DataGridRef } from '@gower/react-ui/template/data-grid';
import { AiOutlineMedicineBox, AiOutlineUser, MdOutlineDownload } from '@gower/react-ui/icons';
import { Payout } from '@gower/apsc-sdk/payouts';

export const PayoutsMobile = () => {

    const navigate = useNavigate();
    const store = useStore();
    const gridRef = React.useRef<DataGridRef>();

    function open ( authId: string ) {
        //navigate( `${ RoutesEnum.Payouts }/${ authId }` );
    }

    function search ( text: string ) {
        gridRef?.current?.search( text );
    }

    return <>
        <Content start columns scroll>
            <Col
                fullWidth
                padding={10}
            >
                <Div padding={10} >
                    <Label xxsemi p bold marginBottom={5}>Historial de Liquidaciones</Label>
                    <Label xsmall p textGrey>Liquidaciones asociadas a su matrícula.</Label>
                </Div>
            </Col>

            <Spacer vertical white>
                <DataGrid
                    ref={gridRef}
                    store={store}
                    loading={store.Status.isLoading}
                    showPagination
                    mobileTemplate={( { data }: { data: Payout; } ) => (
                        <Row
                            white
                            center
                            borderRadius={5}
                            padding={5}
                            noSelect
                            noWrap
                            onClick={() => open( data.Id )}
                        >
                            <Col
                                stretch
                                width={4}
                                color="surface-2"
                            />
                            <Spacer center gutter paddingX={8} ellipsis>
                                <Row columns>
                                    <Col >
                                        <Col center gutter>
                                            <Label xxsmall marginRight={4}>Liquidación</Label>
                                            <Label normal bold>{data.RegistryNumber}/{data.Code}</Label>
                                        </Col>
                                        <Spacer />
                                        <Col center gutter>
                                            <Label xxsmall textGrey label={( data.Date as any ).toStringDate( 'short', false )} />
                                        </Col>
                                        <Col center gutter>
                                            <Div
                                                onClick={() => store.download( data )}
                                                activeEffect
                                                primary
                                                rounded
                                                padding={6}
                                                lineHeight={0}
                                                textColor="white"
                                            >
                                                <MdOutlineDownload size={16} />
                                            </Div>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Row rows>
                                            <Col start gutter>
                                                <Div block>
                                                    <Label p>
                                                        <Label xsmall bold marginRight={4}>Bruto</Label>
                                                        <Label xxsmall label={data.GrossAmount.toCurrency()} />
                                                    </Label>
                                                    <Label p>
                                                        <Label xsmall bold marginRight={4}>Ret.</Label>
                                                        <Label xxsmall label={data.WithholdingAmount.toCurrency()} />
                                                    </Label>
                                                </Div>
                                            </Col>
                                            <Spacer />
                                            <Col center gutter>
                                                <Div>
                                                    <Label p xsmall bold marginRight={4}>Total</Label>
                                                    <Label p xnormal bold label={data.TotalAmount.toCurrency()} />
                                                </Div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Spacer>
                            {/* <Col center gutter>
                                <Div textRight>
                                    <Label p xsmall label={data.AuthorizationDate.toStringDate( 'short', true )} />
                                    <Label p xsmall label={data.Agreement.Description} />
                                    <Label p xxsmall
                                        xsmall
                                        textRed={data.Status.isReject || data.Status.isErrored || data.Status.isCanceled}
                                        textGreen={data.Status.isAccepted}
                                        label={data.Status.Description} />
                                </Div>
                            </Col> */}
                        </Row>
                    )}
                >
                    <DataGridColumn
                        field="LocalId"
                        title="Nº de registro"
                        size={80}
                        minSize={50}
                        template={( { data } ) => (
                            <Div block>
                                <Label p >
                                    {data.RegistryNumber}
                                </Label>
                            </Div>
                        )}
                    />
                </DataGrid>
            </Spacer>

        </Content>
    </>;
};

