import { Col, Title, Row, Label, Div } from '@gower/react-ui/components';
import { AppLogo, AppName } from 'lib/components/logo';

export const HeaderLogin = ( { title } ) => {
    return (
        <Col fullWidth center justifyCenter style={{ justifyContent: 'center' }}>
            <Row justifyCenter fullWidth noWrap>
                <Col center pr-2 >
                    <AppLogo size={66} />
                </Col>
                <Col center >
                    <Div block noScroll noSelect>
                        <AppName marginBottom={-3} size="xx-small" width={256} />
                    </Div>
                </Col >
            </Row>
            <Row pt-8 justifyCenter>
                <Col>
                    <Title large textWeight='500' letterSpacing="wide" label={title} />
                </Col>
            </Row>
        </Col>
    );
};