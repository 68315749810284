import { Payout, Filters, PayoutsService } from '@gower/apsc-sdk/payouts';
import { ViewStatus } from '@gower/models-sdk/utils';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';
import { SelectFile } from '@gower/react-ui/utils';
import CredentialProvider from 'app/credential-provider';

class PayoutsStore extends DataGridStore( Payout, Filters, { viewName: 'PAYOUTS' } )
{
    public readonly DownloadStatus = ViewStatus.new( 'ok' );
    public downloadId = null;
    public uploadId = null;
    public downloadInvoiceId = null;

    async download ( item: Payout ) {

        this.downloadId = item.Id;
        this.DownloadStatus.setLoading();
        const file = await item.getDownloadUrl();
        if ( file?.url ) {
            const link = document.createElement( 'a' );
            link.href = file.url;
            link.setAttribute( 'download', file['id'] );
            document.body.appendChild( link );
            link.click();
            link.remove();
        } else {
            await MessageBox.Error();
        }
        this.downloadId = null;
        this.DownloadStatus.setOk();
    }

    async uploadInvoice ( item: Payout ) {

        SelectFile( async (
            file
        ) => {

            if ( file ) {
                this.DownloadStatus.setLoading();
                this.uploadId = item.Id;
                const result = await item.uploadInvoice( file.file );
                if ( result?.result ) {
                    Notifications.Success( 'Guardado correctamente.' );
                    item.CanUploadInvoice = false;
                } else {
                    if ( result?.errors ) {
                        await MessageBox.Error( {
                            content: result?.errors
                        } );
                    } else {
                        Notifications.Error( 'Ocurrió un error al guardar.' );
                    }
                }
                this.uploadId = null;
            }

            this.DownloadStatus.setOk();
        }, {
            multiple: false,
        } );
    }

    async downloadInvoice ( item: Payout ) {

        this.downloadInvoiceId = item.Id;
        this.DownloadStatus.setLoading();
        if ( item.InvoiceURL ) {
            const link = document.createElement( 'a' );
            link.href = item.InvoiceURL;
            link.setAttribute( 'download', item.Id );
            document.body.appendChild( link );
            link.click();
            link.remove();
        } else {
            await MessageBox.Error();
        }
        this.downloadInvoiceId = null;
        this.DownloadStatus.setOk();
    }

    async downloadPending () {

        const license = CredentialProvider.getUserLicense();

        this.DownloadStatus.setLoading();
        const file = await new PayoutsService().downloadPendingPayouts( license );
        if ( file?.url ) {
            const link = document.createElement( 'a' );
            link.href = file.url;
            link.setAttribute( 'download', file['id'] );
            document.body.appendChild( link );
            link.click();
            link.remove();
        } else {
            await MessageBox.Error();
        }
        this.downloadId = null;
        this.DownloadStatus.setOk();
    }

    async downloadList () {
        // const service = new AuthorizationsService();
        // this.DownloadStatus.setLoading();
        // const file = await service.getListUrl( this.Finder.Filters.fechParams() as any );
        // if ( file?.url ) {
        //     window.open( file.url );
        // } else {
        //     await MessageBox.Error( service.LastErrors );
        // }
        // this.DownloadStatus.setOk();
    }
}

export const { useStore, provider } = expose( PayoutsStore );