import React from "react";
import { Div, DivProps, LabelProps, Title } from '@gower/react-ui/components';

export type AppNameProps = DivProps & {
  size?: LabelProps['textSize'];
};

export const AppName: React.FunctionComponent<AppNameProps> = ( { size, color, ...props } ) => {

  return (
    <Div block textLeft {...props}>
      <Title textSize={size} ma-0 bold letterSpacing="wide" textColor="blue-7" label="ASOCIACION PROFESIONALES" />
      <Title textSize={size} ma-0 bold letterSpacing="wide" textColor="blue-7" label="DE LA SALUD DE CAMPANA" />
    </Div>
  );

};

