import type { DrawerItemType } from '@gower/react-ui/template/drawer';
import { adminOnly } from 'app/credential-provider';

export enum RoutesEnum {
    BaseAuth = '/a',
    Login = '/a/signin',
    ActivateUser = '/a/activate-user',
    ForgotPassword = '/a/forgot-password',
    RestorePasswordUser = '/a/signin',

    Base = '/',
    Home = '/',
    Users = '/manage/users',
    UsersImport = '/manage/users/import',
    UsersCreate = '/manage/users/create',
    UsersEdit = '/manage/users/edit',
    SecureCodes = '/manage/secure-codes',
    Profile = '/profile',
    ChangePassword = '/profile/psw',
    EditUser = '/profile/edit-user',

    Payouts = '/payouts',
    Calculator = '/calculator',
    PayoutInvoices = '/payouts/invoices',
}


export const DrawerItems: DrawerItemType[] = [

    { link: RoutesEnum.Home, label: 'Inicio' },
    { type: 'title', label: 'Liquidaciones' },
    { link: RoutesEnum.Payouts, label: 'Historial de Liquidaciones' },
    { link: RoutesEnum.Calculator, label: 'Valores de Prestaciones' },
    { link: RoutesEnum.PayoutInvoices, label: 'Prestaciones Facturadas' },
    { type: 'divider', security: adminOnly },
    { type: 'title', label: 'Configuración', security: adminOnly },
    { link: RoutesEnum.Users, label: 'Usuarios', security: adminOnly },
    { link: RoutesEnum.SecureCodes, label: 'Códigos de seguridad', security: adminOnly },
];
