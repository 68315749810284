import dayjs from 'dayjs';
import { Col, Content, Div, Label, Row, Spacer, Button, Autocomplete2, Alert, Divider, Colors, TextInput2, Tooltip, LightTooltip, TextDateInput2 } from '@gower/react-ui/components';
import { useStore } from '../store';
import { useUserLicense } from 'features/hooks/useUserLincese';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';
import { Payout } from '@gower/apsc-sdk/payouts';

export const CalculatorMobile = () => {

    const store = useStore();
    const { license } = useUserLicense();

    return <>
        <Content columns color="white" scrollOnlyVertical>
            <Col padding={5} white>
                <Content columns justifyCenter gap={10}>

                    <Col gutter fullWidth>
                        <Alert
                            fullWidth
                            title="Estimado usuario."
                            content="Ingresé la información requerida y pulse Calcular"
                        />
                    </Col>

                    <Col center borderRadius={5} fullWidth maxWidth={800} padding={20}>
                        <Row columns>
                            <Col height={30} />
                            <Col gutter>
                                <TextInput2
                                    label="Matrícula"
                                    value={license}
                                    readOnly
                                    contentRight={(
                                        <Col center paddingX={5} paddingTop={5}>
                                            <Tooltip title="La matrícula no puede editarse">
                                                <MdOutlineHelpOutline size={20} color={Colors['primary']} />
                                            </Tooltip>
                                        </Col>
                                    )}
                                />
                            </Col>
                            <Col gutter>
                                <TextDateInput2
                                    label="Fecha de Vigencia"
                                    value={store.date}
                                    onChangeValue={( v ) => store.date = v}
                                    contentRight={(
                                        <Col center>
                                            <Button text bold label="Hoy" onClick={() => store.date = dayjs()} />
                                        </Col>
                                    )}
                                />
                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    submitOnEnter
                                    disabled={!store.Status.isPending}
                                    label="Prestación"
                                    clearable
                                    placeholder="Escriba la descripción o código de la prestación a consultar"
                                    optionsAsync={( q ) => store.findHealthServices( q )}
                                    value={store.healthService}
                                    onChangeValue={v => store.healthService = v}
                                    displayKey={( { item, format } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold html={format( item.Id, { color: Colors['primary'] } )} />
                                            </Col>
                                            <Col gutter>
                                                <Label html={format( item.Description )} />
                                            </Col>
                                        </Row>
                                    )}
                                    selectedKey={( { item } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold label={item.Id} />
                                            </Col>
                                            <Col gutter>
                                                <Label label={item.Description} />
                                            </Col>
                                        </Row>
                                    )}
                                />

                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    select
                                    disabled={!store.Status.isPending}
                                    label="Actuación"
                                    clearable
                                    placeholder="Seleccione su actuación"
                                    options={Payout.VO.Role}
                                    value={store.role}
                                    onChangeValue={v => store.role = v}
                                    displayKey="Description"
                                />
                            </Col>
                            <Col gutter>
                                <Autocomplete2
                                    submitOnEnter
                                    disabled={!store.Status.isPending}
                                    label="Obra social"
                                    clearable
                                    placeholder="Seleccione la Obra Social a consultar"
                                    hint="Seleccione una Obra Social o dejelo en blanco para cotizar todas."
                                    suffix={!store.healthEntity ? "(Vacio todas)" : ""}
                                    optionsAsync={( q ) => store.findAllHealthEntities( q )}
                                    filterKey={["Description", "Id"]}
                                    value={store.healthEntity}
                                    onChangeValue={v => store.healthEntity = v}
                                    displayKey={( { item, format } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold html={format( item.Id, { color: Colors['primary'] } )} />
                                            </Col>
                                            <Col gutter>
                                                <Label html={format( item.Description )} />
                                            </Col>
                                        </Row>
                                    )}
                                    selectedKey={( { item } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold label={item.Id} />
                                            </Col>
                                            <Col gutter>
                                                <Label label={item.Description} />
                                            </Col>
                                        </Row>
                                    )}
                                />

                            </Col>

                            <Col height={30} />
                            <Divider marginY={12} show={store.Status.isOk} />

                            <Col gutter show={store.Status.isOk}>
                                <Div>
                                    <Label p xxsmall light label="Resultado de la valorización:" />
                                </Div>
                            </Col>

                            {( store.payoutCalculatorResult?.length > 0 ) &&
                                <Col gutter show={store.Status.isOk}>
                                    <Row color="surface-0" border borderColor="surface-4" rounded padding={10}>
                                        <Col cols={6} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Matrícula" />
                                                <Label p bold xnormal label={license} />
                                            </Div>
                                        </Col>
                                        <Col cols={6} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Actuación" />
                                                <Label p bold xnormal label={store.role?.Description} />
                                            </Div>
                                        </Col>
                                        <Col cols={12} padding={10}>
                                            <Div>
                                                <Label p xsmall label="Prestación" />
                                                <Label p bold xnormal label={store.healthService?.Description} />
                                            </Div>
                                        </Col>
                                        <Col cols={12}>
                                            {store.payoutCalculatorResult.map( ( item, idx ) => (
                                                <Row fullWidth key={idx}>
                                                    <Col cols={12} gutter>
                                                        <Div>
                                                            <Label bold gutter normal label={item.HealthEntity?.Id} />
                                                            <Label normal label={item.HealthEntity?.Description} />
                                                        </Div>
                                                    </Col>
                                                    <Col cols={12} horizontalGutter>
                                                        <Div>
                                                            <Label xsmall gutter label="Gastos:" />
                                                            <Label normal label={item.Expenses.toCurrency()} />
                                                        </Div>
                                                    </Col>
                                                    <Col cols={12} horizontalGutter>
                                                        <Div>
                                                            <Label xsmall gutter label="Honorarios:" />
                                                            <Label normal label={item.ProfessionalFee.toCurrency()} />
                                                        </Div>
                                                    </Col>
                                                    <Col cols={12} horizontalGutter>
                                                        <Div>
                                                            <Label xsmall gutter label="Total:" />
                                                            <Label bold normal label={item.Total.toCurrency()} />
                                                        </Div>
                                                    </Col>
                                                    <Divider gutter/>
                                                </Row>
                                            ) )}
                                        </Col>
                                    </Row>
                                </Col>}

                            <Col gutter>
                                <Row>
                                    <Col cols={12} gutter height={64}>
                                        <Button fullHeight fullWidth primary xnormal letterSpacing="wide" label="Descargar" loading={store.StatusDownload.isLoading} disabled={!store.canCalculate || store.Status.isLoading} onClick={() => store.download()} />
                                    </Col>
                                    <Col cols={12} gutter show={!store.Status.isOk} height={64}>
                                        <Button fullHeight fullWidth primary xnormal letterSpacing="wide" label="Calcular" loading={store.Status.isLoading} disabled={!store.canCalculate} onClick={() => store.calc()} />
                                    </Col>
                                    <Col cols={12} gutter show={store.Status.isOk} height={64}>
                                        <Button fullHeight fullWidth primary xnormal letterSpacing="wide" label="Restablecer" loading={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                    <Col cols={12} gutter show={!store.Status.isOk} height={64}>
                                        <Button fullHeight fullWidth outlined borderColor="surface-4" label="Restablecer" disabled={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Col>
        </Content>
    </>;
};