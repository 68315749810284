import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Div, Button, Spacer, Row, Col, Content, Label } from '@gower/react-ui/components';
import { AiOutlineUser, AiFillLock, AiFillEye, AiFillEyeInvisible } from '@gower/react-ui/icons';
import { useLogin } from 'features/hooks/useLogin';
import { HeaderLogin } from 'lib/components/header-login';
import { RoutesEnum } from 'app/routes';

export const LoginView = () => {
    return <>
        <Content fullHeight fullWidth scroll justifyCenter columns>
            <Col
                center
                width="auto"
                maxWidth={400}
                minWidth={350}
                minHeight={400}
                maxHeight="90%"
                useMediaQuery
                xsWidth="100%"
                xsMaxWidth="100%"
                xsHeight="100%"
                xsMaxHeight="100%"
                white
                box-shadow-1
                paddingY={64}
                paddingX={32}
            >
                <LoginForm />
            </Col>
        </Content>
    </>;
};

const LoginForm = () => {
    const { message, login, password, setPassword, setUsername, status, username } = useLogin();
    const [showPassword, setShowPassword] = React.useState( false );

    return <>
        <Content columns fullHeight>
            <HeaderLogin title="Iniciar sesión" />
            <Col fullWidth center py-5 display='block'  >

                <Label p normal textWeight='500' letterSpacing="wide" textGrey mb-4 label='Usuario o email' />
                <TextField
                    id="username"
                    autoComplete='username'
                    autoCapitalize='off'
                    autoCorrect='off'
                    placeholder="Ingresá tu usuario"
                    variant="standard"
                    size='medium'
                    fullWidth
                    autoFocus
                    disabled={status.isLoading}
                    value={username}
                    onChange={( e ) => setUsername( e.target.value )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiOutlineUser size={20} />
                            </InputAdornment>
                        ),
                    }}

                />
                <Div mt-5 />
                <Label p normal textWeight='500' letterSpacing="wide" textGrey mb-4 label='Contraseña' />
                <TextField
                    id="password"
                    autoComplete='password'
                    autoCapitalize='off'
                    autoCorrect='off'
                    placeholder="Ingresá tu contraseña"
                    variant="standard"
                    size='medium'
                    fullWidth
                    disabled={status.isLoading}
                    value={password}
                    onChange={( e ) => setPassword( e.target.value )}
                    onKeyUp={( e ) => e.key === 'Enter' && login()}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiFillLock size={20} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <Button noFocuseable text circle pointer size={20} textGrey onClick={() => setShowPassword( !showPassword )}>
                                    {!showPassword && <AiFillEye size={18} />}
                                    {showPassword && <AiFillEyeInvisible size={18} />}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    text
                    pointer
                    textGrey
                    reactLink={RoutesEnum.ForgotPassword}
                    justify="flex-start"
                    disabled={status.isLoading}
                    label="Olvidé mi contraseña"
                />
            </Col>
            <Spacer vertical>
                <Div fullWidth minHeight={40} pt-2 textCenter>
                    <Label textRed normal>{message}</Label>
                </Div>
                <Div fullWidth useMediaQuery height={46} xsHeight={64}>
                    <Button
                        pointer
                        primary
                        fullWidth
                        fullHeight
                        textWeight='500'
                        textSize="normal"
                        letterSpacing='wide'
                        loading={status.isLoading}
                        onClick={() => login()}
                        label="Ingresar"
                    />
                </Div>
                <Div fullWidth mt-2 useMediaQuery height={46} xsHeight={64} hidden>
                    <Button
                        disabled={status.isLoading}
                        outlined
                        fullWidth
                        fullHeight
                        textSize="normal"
                        textColor='blue-7'
                        textWeight='500'
                        letterSpacing='wide'
                        label="Activá tu usuario"
                        reactLink={RoutesEnum.ActivateUser}
                    />
                </Div>
            </Spacer>

        </Content>
    </>;
};