import dayjs from 'dayjs';
import { Col, Content, Div, Label, Row, Spacer, Button, Alert, Divider, Colors, TextInput2, Tooltip, TextDateInput2 } from '@gower/react-ui/components';
import { useStore } from '../store';
import { useUserLicense } from 'features/hooks/useUserLincese';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';
import { Loader } from '@gower/react-ui/template/loader';

export const PayoutInvoicesDesktop = () => {

    const store = useStore();
    const { license } = useUserLicense();

    if ( store.ready.isLoading )
        return <>
            <Content columns justifyCenter>
                <Col center>
                    <Loader
                        status={store.ready}
                        loadingTitle=""
                    />
                </Col>
            </Content>
        </>;

    return <>
        <Content columns color="surface-0" scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Prestaciones facturadas</Label>
                            <Label xsmall p textGrey>Descargue sus prestaciones facturadas.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center hidden>
                        <Alert
                            fullWidth
                            title="Estimado usuario."
                            content="Ingresé la información requerida y pulse Descargar"
                        />
                    </Col>
                </Row>
            </Col>
            <Col padding={10} >
                <Content columns justifyCenter gap={10}>
                    <Col gutter center fullWidth maxWidth={800} show={Boolean( store.message )}>
                        <Div
                            fullWidth
                            padding={20}
                            color="blue-6"
                            textWhite
                            rounded
                        >
                            <Div html={store.message.replace( /(?:\\[r]|[\r])/g, "</br>" )} />
                        </Div>
                    </Col>
                    <Col center white borderRadius={5} fullWidth maxWidth={800} padding={20}>
                        <Row columns>
                            <Col height={30} />
                            <Col gutter>
                                <TextInput2
                                    label="Matrícula"
                                    value={license}
                                    readOnly
                                    contentRight={(
                                        <Col center paddingX={5} paddingTop={5}>
                                            <Tooltip title="La matrícula no puede editarse">
                                                <MdOutlineHelpOutline size={20} color={Colors['primary']} />
                                            </Tooltip>
                                        </Col>
                                    )}
                                />
                            </Col>
                            <Col gutter>
                                <TextDateInput2
                                    label="Desde fecha"
                                    value={store.startDate}
                                    onChangeValue={( v ) => store.startDate = v}
                                />
                            </Col>
                            <Col gutter>
                                <TextDateInput2
                                    label="Hasta fecha"
                                    value={store.endDate}
                                    onChangeValue={( v ) => store.endDate = v}
                                    contentRight={(
                                        <Col center>
                                            <Button text bold label="Hoy" onClick={() => store.endDate = dayjs()} />
                                        </Col>
                                    )}
                                />
                            </Col>

                            <Col height={30} />
                            <Divider marginY={12} show={store.Status.isOk} />

                            <Col gutter>
                                <Row>
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button width={120} text label="Restablecer" disabled={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                    <Spacer />
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button width={140} primary normal letterSpacing="wide" label="Descargar" loading={store.Status.isLoading} disabled={!store.canDownload} onClick={() => store.download()} />
                                    </Col>
                                    <Col gutter show={store.Status.isOk}>
                                        <Button width={140} primary letterSpacing="wide" label="Restablecer" loading={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Content>
            </Col>
        </Content>
    </>;
};