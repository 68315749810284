import dayjs from 'dayjs';
import { PayoutCalculateService, PayoutInvoicesService } from '@gower/apsc-sdk/payouts';
import { MutableStore, ViewStatus, expose } from '@gower/react-ui/store';
import { MessageBox } from '@gower/react-ui/message-box';
import CredentialProvider from 'app/credential-provider';

class PayoutInvoicesStore extends MutableStore {

    
    public readonly Status = new ViewStatus( 'pending' );
    public readonly ready = new ViewStatus( 'loading' );

    private payoutInvoicesService = new PayoutInvoicesService();
    private payoutCalculateService = new PayoutCalculateService();

    public startDate = dayjs().add( -30, 'day' );
    public endDate = dayjs();
    public message: string = null;

    public get canDownload () {
        return Boolean(
            this.startDate?.isValid() &&
            this.endDate?.isValid() 
        );
    }

    
    protected async onBeforeRender ( props?: any ) {
        this.ready.setLoading()
        await this.getMessage();
        this.ready.setOk()
    }

    async getMessage () {
        this.message = '';
        const license = CredentialProvider.getUserLicense();
        const data = await this.payoutCalculateService.getMessage( license );
        this.message = data.message;
    }

    async reset () {
        this.startDate = dayjs().add( -30, 'day' );
        this.endDate = dayjs();
        
        this.Status.setPending();
    }

    async download () {

        this.Status.setLoading();
        const license = CredentialProvider.getUserLicense();
        const file = await this.payoutInvoicesService.file( {
            license,
            startDate: this.startDate,
            endDate: this.endDate,
        } );

        if ( file?.url ) {
            const link = document.createElement( 'a' );
            link.href = file.url;
            link.setAttribute( 'download', file['id'] );
            document.body.appendChild( link );
            link.click();
            link.remove();
        }
        else {
            await MessageBox.Error( {
                content: this.payoutInvoicesService.LastErrors
            } );
        }

        this.Status.setPending();

    }
 

}

export const { useStore, provider } = expose( PayoutInvoicesStore );