import React from 'react';
import CredentialProvider from 'app/credential-provider';

export function useUserLicense () {

    const [license, setLicense] = React.useState( '' );

    React.useEffect( () => {
        setLicense( CredentialProvider.getUserLicense() || '' );
    }, [] );

    return {
        license
    };
}