import React from 'react';
import { Professional, ProfessionalsService } from '@gower/apsc-sdk/services';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete } from '@gower/react-ui/components';
import { useBoolean, ViewStatus } from '@gower/react-ui/hooks';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose } from '@gower/react-ui/store';
import { ErrorManager } from '@gower/react-ui/utils';
import { User } from '@gower/shared-sdk/core/users';
import { ProfileStoreSubstore } from '../store';
import { Validators } from '@gower/utils';


class UsersEditStore extends ProfileStoreSubstore {

    public readonly Status = ViewStatus.new( 'loading' );
    public readonly ErrorManager = ErrorManager.new( {
        autoClearTimeOut: 4000,
        fields: ['Nickname', 'Name', 'Email', 'Group', 'Status']
    } );

    public EditItem = new User();
    public Licenses: Professional[] = [];

    async onAfterRender () {
        await this.reset();
    }

    public async reset () {
        this.Status.setLoading();
        this.ErrorManager.clear();
        this.Licenses.clear();
        this.EditItem = User.clone( this.Item );

        if ( Array.isArray( this.EditItem.Metadata.licenses ) )
            for ( const license of this.EditItem.Metadata.licenses ) {
                await this.addLicense( license );
            }

        this.Status.setOk();
    }

    public async manualReset () {

        const result = await MessageBox.YesNo( {
            title: 'Restablecer',
            content: 'Se perderán datos no guardados.'
        } );
        if ( result ) {
            this.reset();
        }
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Group || this.EditItem.Group.isUnspecified ) this.ErrorManager.setGroup( 'Completá este campo' );
        if ( !this.EditItem.Status || this.EditItem.Status.isUnspecified ) this.ErrorManager.setStatus( 'Completá este campo' );
        if ( !this.EditItem.Nickname || this.EditItem.Nickname.length < 4 ) this.ErrorManager.setNickname( 'Completá este campo' );
        if ( !this.EditItem.Name || this.EditItem.Name.length < 2 ) this.ErrorManager.setName( 'Completá este campo' );
        if ( !Validators.Email( this.EditItem.Email ) ) this.ErrorManager.setEmail( 'Completá este campo con un email válido' );

        return !this.ErrorManager.hasErrors();
    }

    public async removeLicense ( license: string ) {
        this.Licenses.remove( item => Number( item.License ) === Number( license ) );
    }

    public async addLicense ( license: string ) {

        if ( !license || license.length < 4 || Number.isNaN( Number( license ) ) )
            return;

        if ( this.Licenses.every( item => Number( item.License ) !== Number( license ) ) ) {

            const professional = await new ProfessionalsService().findOne(
                license
            );

            if ( professional ) {
                this.Licenses.clear();
                this.Licenses.push( professional );
            } else {
                Notifications.Info( 'Matrícula no existe en la base de datos' );
            }
        }
    }


    public async save () {

        if ( this.checkErrors() ) {
            this.Status.setSaving();
            this.EditItem.Metadata.licenses = this.Licenses.map( item => item.License );

            const result = await this.EditItem.save();
            if ( result?.result ) {

                Notifications.Saved();
                await this.Item.refresh( this.EditItem );

            } else {
                await MessageBox.Error( {
                    content: result.errorList
                } );
            }

            this.Status.setOk();

        }
    }
}


const { useStore, provider } = expose( UsersEditStore );


export const UsersEditForm = provider( () => {

    const store = useStore();
    const { EditItem } = store;

    return <>
        <Row paddingBottom={10}>
            <Col gutter center cols={12}>
                <Label p bold normal>Editar información básica</Label>
            </Col>
            <Col cols={12}>
                <Divider />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Nickname</Label>
                    <Label p textGrey xsmall>No puede editarse</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Nickname}
                    placeholder="Nickname de usuario"
                    readOnly
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Grupo</Label>
                    <Label p textGrey xsmall>Tu tipo de usuario</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <Autocomplete
                    filled
                    placeholder="Grupo del usuario"
                    options={User.VO.Group.getOptions()}
                    value={EditItem.Group}
                    readOnly
                    errorMessage={store.ErrorManager.getEmail()}
                />
            </Col>
        </Row>


        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Estado</Label>
                    <Label p textGrey xsmall>Estado en el sistema</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <Autocomplete
                    filled
                    placeholder="Estado del usuario"
                    options={User.VO.Status.getOptions()}
                    value={EditItem.Status}
                    readOnly
                    errorMessage={store.ErrorManager.getEmail()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Email</Label>
                    <Label p textGrey xsmall>Tu correo electrónico</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Email}
                    onChangeText={t => EditItem.Email = t}
                    placeholder="Email"
                    readOnly={!store.Status.isOk}
                    hint="Ingresá un email"
                    error={store.ErrorManager.getEmail()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Nombre</Label>
                    <Label p textGrey xsmall>Tu nombre completo</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Name}
                    onChangeText={t => EditItem.Name = t}
                    placeholder="Nombre del usuario"
                    readOnly={!store.Status.isOk}
                    error={store.ErrorManager.getName()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Apellido</Label>
                    <Label p textGrey xsmall>Tu apellido</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Lastname}
                    onChangeText={t => EditItem.Lastname = t}
                    placeholder="Apellido del usuario"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Celular</Label>
                    <Label p textGrey xsmall>Tu celular</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Cellphone}
                    onChangeText={t => EditItem.Cellphone = t}
                    placeholder="Teléfono celular"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Teléfono</Label>
                    <Label p textGrey xsmall>Tu teléfono laboral</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={EditItem.Telephone}
                    onChangeText={t => EditItem.Telephone = t}
                    placeholder="Teléfono laboral"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>

        <LicensesManager />

        <Row justifyEnd >
            <Col gutter >
                <Button
                    actionTheme
                    disabled={!store.Status.isOk}
                    label="Restablecer"
                    onClick={() => store.manualReset()}
                />
            </Col>
            <Col gutter >
                <Button
                    primary
                    disabled={store.Status.isLoading}
                    loading={store.Status.isSaving}
                    onClick={() => store.save()}
                    label="Guardar cambios"
                />
            </Col>
        </Row>

    </>;
} );



const LicensesManager = () => {

    const store = useStore();
    const loading = useBoolean();
    const [value, setValue] = React.useState( '' );

    async function add () {
        loading.enable();
        await store.addLicense( value );
        setValue( '' );
        loading.disable();
    }

    async function remove ( item ) {
        loading.enable();
        await store.removeLicense( item.License );
        setValue( '' );
        loading.disable();
    }

    return <>
        <Row rows>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Matrícula</Label>
                    <Label p textGrey xsmall>Matrícula habilitada</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>

            </Col>
            <Col gutter start cols={12} minHeight={100}>
                <Div block textCenter padding={20} color="surface-2" show={!store.Licenses.length}>

                </Div>
                {store.Licenses.map( ( item, idx ) => ( <>
                    <Row padding={5} noSelect hoverEffect key={idx}>
                        <Col gutter center>
                            <Div block>
                                <Label p xxsmall bold>{item.License}</Label>
                                <Label p xsmall bold>{item.Name}</Label>
                            </Div>
                        </Col>
                        <Spacer />
                    </Row>
                </> ) )}
            </Col>
        </Row>
    </>;
};