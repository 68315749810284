
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { User } from '@gower/shared-sdk/core/users';


class UsersEditorStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'pending' );
    public Item: User = null;
    public EditItem: User = null;
    public Licenses: string = '';
    private _onClose;

    onReceiveProps ( props ) {

        this._onClose = props?.onClose;

        if ( props?.selected !== this.Item ) {

            this.Item = props.selected;
            this.EditItem = User.clone( props.selected );

            if ( !Array.isArray( this.EditItem.Metadata.licenses ) ) {
                this.EditItem.Metadata.licenses = [];
            }

            this.Licenses = this.EditItem.Metadata.licenses.join( ',' );
            this.Status.setPending();
        }

    }

    public async save () {

        this.Status.setSaving();
        this.EditItem.Metadata.licenses = this.Licenses.split( ',' );
        const result = await this.EditItem.save();
        if ( result?.result ) {
            this.Item.refresh( this.EditItem.getJson() );
            Notifications.Updated();
            this._onClose && this._onClose( this.Item );
        } else {
            await MessageBox.Error( {
                content: result.errorList
            } );
        }

        this.Status.setPending();

    }
}

export const { useStore, provider } = expose( UsersEditorStore );