import { ResponsiveView } from '@gower/react-ui/template/responsive-view';
import { provider } from './store';
import { PayoutsDesktop } from './desktop';
import { PayoutsMobile } from './mobile';

export const PayoutsView = provider( () => {

    return <>
        <ResponsiveView
            desktop={PayoutsDesktop}
            mobile={PayoutsMobile}
        />
    </>;
} );
