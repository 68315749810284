import { Col, Content, Div, Label, Row, Spacer, Button, TextInput2 } from '@gower/react-ui/components';
import { useStore } from '../store';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { Payout, PayoutsService } from '@gower/apsc-sdk/payouts';
import { RoutesEnum } from 'app/routes';
import { useUserLicense } from 'features/hooks/useUserLincese';
import { FiCheck, FiDownloadCloud, FiUploadCloud } from '@gower/react-ui/icons';

export const PayoutsDesktop = () => {

    const store = useStore();
    const { license } = useUserLicense();


    return <>
        <Content columns color="surface-0">
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Historial de Liquidaciones</Label>
                            <Label xsmall p textGrey>Liquidaciones asociadas a su matrícula.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center>
                        {/* <Button
                            actionTheme
                            icon={<MdFileDownload size={16} color={Colors['surface-6']} />}
                            label="Exportar"
                            disabled={!store.Status.isOk}
                            loading={store.DownloadStatus.isLoading}
                            onClick={() => store.downloadList()}
                        /> */}
                    </Col>
                    <Col gutter center>
                        <Button
                            primary
                            label="Descargar liquidaciones pendientes"
                            disabled={store.Status.isLoading || store.DownloadStatus.isLoading}
                            onClick={() => store.downloadPending()}
                        />
                    </Col>
                    <Col gutter center>
                        <Button
                            primary
                            label="Valores de Prestaciones"
                            disabled={store.Status.isLoading || store.DownloadStatus.isLoading}
                            legacyLink={RoutesEnum.Calculator}
                        />
                    </Col>
                </Row>
            </Col>
            <Spacer vertical noScroll>
                <DataGrid
                    loader
                    store={store}
                    autoSorting
                    showFilters={["date_range"]}
                    showReload
                    scroll
                    showPagination
                    toolbarTemplate={(
                        <Div width={180}>
                            <TextInput2
                                borderless
                                small
                                prefix="Matrícula:"
                                readOnly
                                value={license}
                            />
                        </Div>
                    )}
                    mobileTemplate={( { data } ) => (
                        <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                            <Col cols={6}>
                                <Label label={data.Id} />
                            </Col>
                            <Col cols={6}>
                                <Label label={data.CompleteName} />
                            </Col>
                        </Row>
                    )}
                >
                    <DataGridColumn
                        field="RegistryNumber"
                        title="Nº de Liquidación"
                        size={80}
                        minSize={50}
                        template={( { data }: DataGridTemplateProps<Payout> ) => (
                            <Div block bold textCenter fullWidth>
                                <Label>
                                    {data.RegistryNumber}/{data.Code}
                                </Label>
                            </Div>
                        )}
                    />
                    <DataGridColumn
                        field="date"
                        title=""
                        size={130}
                        template={( { data }: DataGridTemplateProps<Payout> ) => (
                            <>
                                {data.Date?.toStringDate( 'short', false )}
                            </>
                        )}
                    />
                    <DataGridColumn field="GrossAmount" title="Importe Bruto" cellProps={{ textRight: true }} type="CURRENCY" />
                    <DataGridColumn field="WithholdingAmount" title="Retenciones" type="CURRENCY" />
                    <DataGridColumn cellProps={{ bold: true }} field="TotalAmount" title="Total" type="CURRENCY" />
                    <DataGridColumn
                        field="actions"
                        title=""
                        size={130}
                        template={( { data }: DataGridTemplateProps<Payout> ) => (
                            <Row end noWrap justifyEnd>
                                <Col show={Boolean( data.CanUploadInvoice ) && !data.InvoiceURL} >
                                    <Button block noneTheme text xxsmall bold pointer textColor="primary" icon={FiUploadCloud} label="Subir Factura" disabled={store.DownloadStatus.isLoading} loading={data.Id === store.uploadId} onClick={() => store.uploadInvoice( data )} />
                                </Col>
                                <Col show={Boolean( data.CanUploadInvoice ) && !!data.InvoiceURL} >
                                    <Button block noneTheme text xxsmall bold pointer textColor="primary" icon={FiUploadCloud} label="Editar Factura" disabled={store.DownloadStatus.isLoading} loading={data.Id === store.uploadId} onClick={() => store.uploadInvoice( data )} />
                                </Col>
                                <Col show={!Boolean( data.CanUploadInvoice )} >
                                    <Button block noneTheme text xxsmall bold textColor="green-7" icon={FiCheck} label="Factura cargada" />
                                </Col>
                                <Col show={Boolean( data.InvoiceURL )}>
                                    <Button block noneTheme text xxsmall bold pointer label="Descargar Factura" disabled={store.DownloadStatus.isLoading} loading={data.Id === store.downloadInvoiceId} onClick={() => store.downloadInvoice( data )} />
                                </Col>
                                <Col>
                                    <Button block noneTheme text xxsmall bold pointer icon={FiDownloadCloud} label="Descargar" disabled={store.DownloadStatus.isLoading} loading={data.Id === store.downloadId} onClick={() => store.download( data )} />
                                </Col>
                            </Row>
                        )}
                    />

                </DataGrid>
            </Spacer>
        </Content>
    </>;
};