import { User } from '@gower/shared-sdk/src/lib/Core/User';
import { UsersBulkService } from '@gower/shared-sdk/src/lib/Core/User/CreateBulk';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { Pagination } from '@gower/models-sdk';
import { DataSheetItem } from '@gower/react-ui/template/datasheet-controlled';
import { UploadedFile } from '@gower/react-ui/utils';
import { Validators } from '@gower/utils';
import CredentialProvider from 'app/credential-provider';
import { MessageBox } from '@gower/react-ui/message-box';
import { Payout } from '@gower/apsc-sdk/payouts';

export class ImportUsersPage {
    constructor ( private items: ImportUsersStoreItem[] ) { }

    public Processed = false;
    public get Items () { return this.items; }
}

export class ImportUsersStoreItem extends DataSheetItem<User> {

    public Processed = false;

    getProblems () {
        const problems = [];
        if ( !Validators.Email( this.value?.Email ) )
            problems.push( 'email' );
        if ( this.value?.Nickname.includes( ' ' ) )
            problems.push( 'nickname' );
        if ( !this.value?.Name )
            problems.push( 'name' );
        if ( this.value?.Metadata.text_licenses ) {
            if ( this.value?.Metadata.text_licenses.split( ',' ).some( item => Number( item ).isNan() ) )
                problems.push( 'license' );
            else
                this.value.Metadata.licenses = this.value?.Metadata.text_licenses.split( ',' );
        }

        return problems;
    }

}

export class ImportUsersStore extends MutableStore {

    public Status = ViewStatus.new( 'pending' );
    public CurrentPage: ImportUsersPage = null;
    public Pages: ImportUsersPage[] = [];
    public Pagination = new Pagination();
    public FileName = '';
    public ProcessedPages = 0;

    public next () {
        if ( this.Pagination.CanNext() ) {
            this.Status.setLoading();
            this.Pagination.Page++;
            this.CurrentPage = this.Pages[this.Pagination.Page - 1];
            this.Status.setOk();
        }
    }

    public prev () {
        if ( this.Pagination.CanPrevious() ) {
            this.Status.setLoading();
            this.Pagination.Page--;
            this.CurrentPage = this.Pages[this.Pagination.Page - 1];
            this.Status.setOk();
        }
    }

    protected onAfterRender ( props?: any ) {

    }

    async uploadFile ( file: UploadedFile ) {

        this.FileName = file.name;
        this.Status.setLoading();
        const req = Payout.getServiceClient( '/payouts/csv-to-json' );
        req.formData.append( 'file', file.file, { filename: file.name } );
        req.useformData = true;
        if ( await req.post() ) {

            const items = [];
            const sheet = req.data as string[][];
            for ( const row of sheet ) {

                let licenses = '';
                let nickname = row[0]?.trim();
                let name = row[1]?.trim();
                let email = row[2]?.trim();
                let phone = row[3]?.trim();
                
                if ( Number( nickname ) > 0 ) {
                    licenses = nickname.trim();
                }

                items.push( new ImportUsersStoreItem( new User( {
                    contact: {
                        cellphone: '',
                        email: email,
                        telephone: phone,
                    },
                    account_type: undefined,
                    group: User.VO.Group.Employee.Id,
                    metadata: {
                        imported_by: CredentialProvider.getUser().nickname,
                        text_licenses: licenses,
                    },
                    name: name,
                    lastname: '',
                    nickname: nickname,
                    id: null,
                    config: null,
                    permissions: null,
                    status: User.VO.Status.Active.Id,
                } ) ) );
            }

            this.setup( items );
            this.Status.setOk();
            return;
        }

        this.Status.setPending();
    }

    private setup ( items: any[] ) {

        const perPage = 10;
        const chunks = items.reduce( ( resultArray, item, index ) => {
            const chunkIndex = Math.floor( index / perPage );

            if ( !resultArray[chunkIndex] ) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push( item );

            return resultArray;
        }, [] );

        this.Pages = chunks.map( items => new ImportUsersPage( items ) );

        this.Pagination.Results = perPage;
        this.Pagination.Total = items.length;
        this.Pagination.Pages = this.Pages.length;
        this.Pagination.Page = 0 + 1;
        this.CurrentPage = this.Pages[0];
    }

    public async import () {

        this.Status.setSaving();

        for ( const item of this.CurrentPage.Items ) {
            if ( item.getProblems().length ) {
                await MessageBox.Error( 'Solucioná los errores existentes antes de importar.' );
                this.Status.setOk();
                return;
            }
        }

        if ( !await MessageBox.YesNo( '¿Guardar datos? Esta acción no puede deshacerse.', 'Importar usuarios' ) ) {
            this.Status.setOk();
            return;
        }

        const service = UsersBulkService.get();

        const items: User['Output'][] = this.CurrentPage.Items.map( item => ( {
            cellphone: item.Value.Cellphone,
            email: item.Value.Email,
            group: item.Value.Group.Id,
            lastname: item.Value.Lastname,
            name: item.Value.Name,
            metadata: {
                ...item.Value.Metadata,
                force_update_password: true,
            },
            nickname: item.Value.Nickname,
            password: item.Value.Nickname.toLowerCase(),
            config: undefined,
            permissions: undefined,
            status: undefined,
            telephone: undefined,
        } ) );


        const result = await service.create( items );

        if ( result ) {

            const itemsWithError = result.data.filter( item => Boolean( item.errors?.length ) );
            const itemsCompleted = result.data.filter( item => !Boolean( item.errors?.length ) );

            for ( const item of itemsCompleted ) {
                if ( this.CurrentPage.Items[item.row] ) {
                    this.CurrentPage.Items[item.row].Processed = true;
                }
            }

            if ( itemsWithError.length ) {
                await MessageBox.Error( {
                    title: 'Ocurrieron errores en el proceso.',
                    content: itemsWithError.reduce( ( prev, cur ) => {
                        cur.errors.forEach( item => {
                            prev.push( `[${ cur.row + 1 }] ${ item.message }` );
                        } );
                        return prev;
                    }, [] )
                } );
            }

            this.CurrentPage.Processed = true;
            this.ProcessedPages = this.Pages.filter( item => item.Processed ).length;

            if ( this.Pagination.CanNext() ) {
                this.next();
            }
            else if ( this.Pagination.CanPrevious() ) {
                this.prev();
            }
        } else {
            await MessageBox.Error( {
                content: service.LastErrors
            } );
            this.Status.setOk();
        }

    }

}

export const { useStore, provider } = expose( ImportUsersStore );