import { Button, Col, Colors, Div, Label, Row, Spacer } from '@gower/react-ui/components';
import { useStore } from '../store';
import { Message } from '@gower/apsc-sdk/services';
import { MdRefresh, TbMessage } from '@gower/react-ui/icons';
import { plural } from '@gower/utils';
import { useStatus } from '@gower/react-ui/hooks';
import React from 'react';

function useMessages () {
    const store = useStore();
    const status = useStatus( 'loading' );
    const { Messages } = store;

    async function refresh () {
        status.setLoading();
        await store.search();
        if ( store.Messages.length > 0 ) {
            status.setOk();
        } else {
            status.setEmpty();
        }
    }

    React.useEffect( () => {
        refresh();
    }, [] );

    return {
        status,
        refresh,
        pending: Messages.filter( item => !item.Readed ),
        messages: Messages,
    };
}

export const MessagesDesktop: React.FC = () => {
    const { status, messages, pending, refresh } = useMessages();

    return <>
        <Row
            borderColor="primary"
            border
            paddingY={12}
            paddingX={24}
            marginBottom={4}

        >
            {status.isOk && <>
                <Col center show={pending.length > 0}>
                    <Label textColor="primary" bold normal label={`Tiene ${ pending.length } ${ plural( pending.length, 'mensaje' ) } sin leer.`} />
                </Col>
            </>}
            {status.isLoading && <>
                <Col center>
                    <Label textColor="primary" bold normal label={"Buscando Mensajes"} />
                </Col>
                <Spacer />
            </>}
            {status.isEmpty && <>
                <Col center>
                    <Label textColor="primary" bold normal label={"Sin mensajes."} />
                </Col>
            </>}
            <Spacer />
            <Col center>
                <Button
                    loading={status.isLoading}
                    onClick={() => refresh()}
                    icon={<MdRefresh color={Colors['primary']} size={16} />} />
            </Col>
        </Row>
        <Row
            show={status.isOk}
            columns
            color="white"
            fullWidth
            maxHeight={500}
            minHeight={350}
            scrollOnlyVertical
            paddingY={12}
        >
            {messages.map( ( item, idx ) => <MessageItem first={!idx} key={item.Id} message={item} /> )}
        </Row>
    </>;
};


export const MessagesMobile: React.FC = () => {
    const { status, messages, pending, refresh } = useMessages();

    return <>
        <Row
            borderColor="primary"
            border
            paddingY={12}
            paddingX={24}
            marginBottom={4}

        >
            {status.isOk && <>
                <Col center show={pending.length > 0}>
                    <Label textColor="primary" bold normal label={`Tiene ${ pending.length } ${ plural( pending.length, 'mensaje' ) } sin leer.`} />
                </Col>
            </>}
            {status.isLoading && <>
                <Col center>
                    <Label textColor="primary" bold normal label={"Buscando Mensajes"} />
                </Col>
                <Spacer />
            </>}
            {status.isEmpty && <>
                <Col center>
                    <Label textColor="primary" bold normal label={"Sin mensajes."} />
                </Col>
            </>}
            <Spacer />
            <Col center>
                <Button
                    loading={status.isLoading}
                    onClick={() => refresh()}
                    icon={<MdRefresh color={Colors['primary']} size={16} />} />
            </Col>
        </Row>
        <Row
            show={status.isOk}
            columns
            color="white"
            fullWidth
            minHeight={350}
            paddingY={12}
        >
            {messages.map( ( item, idx ) => <MessageItem first={!idx} key={item.Id} message={item} /> )}
        </Row>
    </>;
};

const MessageItem: React.FC<{ message: Message; first: boolean; }> = ( { message,first } ) => {

    const status = useStatus( 'pending' );
    const store = useStore();

    async function read () {
        status.setLoading();
        await store.read( message );
        status.setOk();
    }

    return <>
        <Row padding={24} columns borderTop={!first} borderColor="surface-4">
            <Col>
                <Row rows noWrap gap={12}>
                    <Col center>
                        {!message.Readed && <TbMessage size={16} />}
                        {message.Readed && <TbMessage size={16} color={Colors['primary']} />}
                    </Col>
                    <Col center>
                        {!message.Readed && <Label p xxsmall bold label="Nuevo mensaje" />}
                        {message.Readed && <Label p xxsmall textColor="primary" label="Mensaje leído" />}
                    </Col>
                    <Spacer />
                    <Col center>
                        <Label p xsmall textGrey label={message.Date?.toStringDate( 'short' )} />
                    </Col>
                    <Col center>
                        {!message.Readed && <Button
                            bold
                            pointer
                            link
                            buttonSmall
                            label="Descartar"
                            loading={status.isLoading}
                            onClick={read}
                        />}
                    </Col>
                </Row>
            </Col>
            <Col paddingX={24}>
                <Div xxsmall fullWidth>
                    <pre style={{ fontFamily: 'inherit', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                        {message.Message}
                    </pre>
                </Div>
            </Col>
        </Row>

    </>;
};