import { User, Filters } from '@gower/shared-sdk/core/users';
import { UsersBulkService } from '@gower/shared-sdk/src/lib/Core/User/CreateBulk';
import { ViewStatus } from '@gower/models-sdk/utils';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';

class UsersStore extends DataGridStore( User, Filters, { viewName: 'USERS' } )
{
    public readonly DownloadStatus = ViewStatus.new( 'ok' );

    async downloadList () {
        const service = UsersBulkService.get();
        this.DownloadStatus.setLoading();
        const file = await service.download( this.Finder.Filters.fechParams() as any );
        if ( file?.url ) {
            window.open( file.url );
        } else {
            await MessageBox.Error( service.LastErrors );
        }
        this.DownloadStatus.setOk();
    }


    public Selected: User = null;
    async edit ( item: User ) {
        this.Selected = item;
    }

    async endEdit ( item: User ) {
        if ( item )
            this.Selected.refresh( item.getJson() );

        this.Selected = null;
    }

    public creating = false;
    async endCreate ( item: User ) {
        if ( item )
            this.Items.unshift( item );
        this.creating = false;
    }
}

export const { useStore, provider } = expose( UsersStore );