import dayjs from 'dayjs';
import { HealthEntitiesSearchService, HealthEntity, HealthService, HealthServicesSearchService } from '@gower/apsc-sdk/services';
import { PayoutCalculatorResult, PayoutCalculateService, Payout } from '@gower/apsc-sdk/payouts';
import { MutableStore, ViewStatus, expose } from '@gower/react-ui/store';
import { MessageBox } from '@gower/react-ui/message-box';
import CredentialProvider from 'app/credential-provider';

class CalculatorStore extends MutableStore {

    public readonly Status = new ViewStatus( 'pending' );
    public readonly StatusDownload = new ViewStatus( 'pending' );

    private healthEntitiesSearchService = new HealthEntitiesSearchService();
    private healthServicesSearchService = new HealthServicesSearchService();
    private payoutCalculateService = new PayoutCalculateService();

    public date = dayjs();
    public healthEntity: HealthEntity = null;
    public healthService: HealthService = null;
    public role: typeof Payout.VO.Role.Assistant;
    public payoutCalculatorResult: PayoutCalculatorResult[] = [];
    public history: PayoutCalculatorResult[] = [];

    public get canCalculate () {
        return Boolean(
            this.healthService?.Exists &&
            !!this.role
        );
    }

    async reset () {
        this.healthEntity = null;
        this.healthService = null;
        this.role = null;
        if ( this.payoutCalculatorResult?.length === 1 )
            this.history.unshift( ...this.payoutCalculatorResult );
        this.payoutCalculatorResult = null;
        this.Status.setPending();
    }

    async calc () {
        this.Status.setLoading();

        const license = CredentialProvider.getUserLicense();
        const result = await this.payoutCalculateService.calculate( {
            license,
            healthEntity: this.healthEntity,
            healthService: this.healthService,
            role: this.role,
            date: this.date,
        } );

        if ( result ) {
            this.payoutCalculatorResult = result;
            this.Status.setOk();
        }
        else {
            await MessageBox.Error( {
                content: this.payoutCalculateService.LastErrors
            } );
            this.Status.setPending();
        }
    }

    async download () {

        this.StatusDownload.setLoading();
        const license = CredentialProvider.getUserLicense();
        const file = await this.payoutCalculateService.excel( {
            license,
            healthEntity: this.healthEntity,
            healthService: this.healthService,
            role: this.role,
            date: this.date,
        } );

        if ( file?.url ) {
            const link = document.createElement( 'a' );
            link.href = file.url;
            link.setAttribute( 'download', file['id'] );
            document.body.appendChild( link );
            link.click();
            link.remove();
        }
        else {
            await MessageBox.Error( {
                content: this.payoutCalculateService.LastErrors
            } );
        }

        this.StatusDownload.setPending();

    }

    async findAllHealthEntities ( query: string ) {

        const data = await this.healthEntitiesSearchService.find( { q: query } );

        return data?.results || [];

    }

    async findHealthServices ( query: string ) {

        const data = await this.healthServicesSearchService.find( {
            q: query,
        } as any );

        return data?.results || [];

    }

}

export const { useStore, provider } = expose( CalculatorStore );