import React from 'react';
import { Autocomplete, Button, Checkbox, Col, Content, Label, ModalWindow, Row, TextInput } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { User } from '@gower/shared-sdk/core/users';

export type UserEditorProps = {
    isOpen: boolean;
    selected: User;
    onClose: ( edited: User ) => void;
};
export const UserEditor: React.FC<UserEditorProps> = provider( ( { isOpen, onClose } ) => {

    const store = useStore();

    function close () {
        onClose( store.Item );
    }

    if ( isOpen && store.EditItem )
        return <>
            <ModalWindow
                overlayProps={{
                    position: 'fixed',
                    zIndex: 10,
                }}
                isOpen={isOpen}
                onClose={close}
                title="Editar usuario"
                height="80vh"
                footerTemplate={<>
                    <Row justifyEnd padding={10}>
                        <Col center gutter>
                            <Button
                                minWidth={100}
                                normal
                                label="Cancelar"
                                onClick={close}
                                disabled={!store.Status.isPending}
                            />
                        </Col>
                        <Col center gutter>
                            <Button
                                actionTheme
                                minWidth={100}
                                bold
                                normal
                                label="Guardar"
                                loading={store.Status.isLoading}
                                onClick={() => store.save()}
                            />
                        </Col>
                    </Row>
                </>}
            >
                <Content
                    fullWidth
                    padding={20}
                    maxWidth={1000}
                    maxHeight={500}
                >
                    <Row start>

                        <Col cols={6} gutter end>
                            <TextInput label='Nickname' readOnly value={store.EditItem.Nickname} />
                        </Col>
                        <Col cols={6} gutter end />
                        <Col cols={6} gutter end>
                            <Autocomplete label="Estado" placeholder={'Estado'} options={User.VO.Status.getOptions()} readOnly={store.Status.isSaving} value={store.EditItem.Status} onChange={( e ) => store.EditItem.Status = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end>
                            <TextInput label='Matriculas' placeholder={'separadas por coma'} readOnly={store.Status.isSaving} value={store.Licenses} onChange={( e ) => store.Licenses = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end />
                        <Col cols={6} gutter end>
                            <TextInput label='Nombre' placeholder={'Nombre del usuario'} suffix={'*'} readOnly={store.Status.isSaving} value={store.EditItem.Name} onChange={( e ) => store.EditItem.Name = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end>
                            <TextInput label='Apellido' placeholder={'Apellido del usuario'} readOnly={store.Status.isSaving} value={store.EditItem.Lastname} onChange={( e ) => store.EditItem.Lastname = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end>
                            <TextInput label='Email' placeholder={'Email del usuario'} suffix={'*'} readOnly={store.Status.isSaving} value={store.EditItem.Email} onChange={( e ) => store.EditItem.Email = e.target.value} />
                        </Col>

                        <Col cols={6} gutter end>
                            <Autocomplete label="Grupo" placeholder={'Grupo del usuario'} options={User.VO.Group.getOptions()} readOnly value={store.EditItem.Group} onChange={( e ) => store.EditItem.Group = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end>
                            <TextInput label='Teléfono' placeholder={'Teléfono del usuario'} readOnly={store.Status.isSaving} value={store.EditItem.Telephone} onChange={( e ) => store.EditItem.Telephone = e.target.value} />
                        </Col>
                        <Col cols={6} gutter end>
                            <TextInput label='Celular' placeholder={'Teléfono celular del usuario'} readOnly={store.Status.isSaving} value={store.EditItem.Cellphone} onChange={( e ) => store.EditItem.Cellphone = e.target.value} />
                        </Col>

                    </Row>
                </Content>
            </ModalWindow>
        </>;

    return null;
} ); 