
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { User } from '@gower/shared-sdk/core/users';


class UsersEditorStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'pending' );
    public EditItem: User = new User;
    public Licenses: string = '';
    private _onClose;

    onReceiveProps ( props ) {

        this._onClose = props?.onClose;

    }

    public close ( item?) {
        this.EditItem = new User;
        this.Licenses = '';
        this.Status.setPending();
        this._onClose && this._onClose( item );
    }

    public async save () {

        this.Status.setSaving();
        
        this.EditItem.Metadata.licenses = this.Licenses.split( ',' );
        const result = await this.EditItem.save();
        if ( result?.result ) {
            const item = new User( this.EditItem.getJson() );
            this.close( item );
            Notifications.Saved();
        } else {
            await MessageBox.Error( {
                content: result.errorList
            } );
        }

        this.Status.setPending();

    }
}

export const { useStore, provider } = expose( UsersEditorStore );