import { Col, Content, Div, Spacer } from '@gower/react-ui/components';
import { AppLogo } from 'lib/components/logo';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const LayoutEmpty: React.FC = () => {
    return <>
        <Content viewPortWidth viewPortHeight color='surface-2' columns>
            <Col>
                {/* <Header /> */}
            </Col>
            <Spacer noScroll vertical
                style={{
                    background: 'linear-gradient(to top, #e5f5fd, #eef7ff, #f5f9ff, #fbfcff, #ffffff)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: "100%"
                }}>
                <Outlet />
            </Spacer>
        </Content>
    </>;
};