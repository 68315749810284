import CredentialProvider from 'app/credential-provider';
import { AuthenticationService } from '@gower/shared-sdk/src/lib/Core/Authentication/Session';
import { WaitAtLeast } from '@gower/utils';

export function useCheckSession () {

    async function check () {
        
        const wait = WaitAtLeast( 1000 );
        const service = AuthenticationService.get();
        const token = CredentialProvider.getToken();
        const user = await service.me( token );
        if ( !user ) {
            CredentialProvider.clear();
        } else {
            CredentialProvider.updateUser( user );
        }
        await WaitAtLeast( wait );
    }

    return {
        check
    };

}