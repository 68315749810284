import React from 'react';
import { useStatus } from '@gower/react-ui/hooks';
import { AuthenticationService } from '@gower/shared-sdk/src/lib/Core/Authentication/Session';
import CredentialProvider from 'app/credential-provider';
import { useLocation, useNavigate } from 'react-router-dom';

export function useLogin ()
{
    const navigate = useNavigate();
    const location = useLocation() as any;
    const status = useStatus( 'ok' );
    const [username, setUsername] = React.useState( '' );
    const [password, setPassword] = React.useState( '' );
    const [message, setMessage] = React.useState( '' );

    async function login ()
    {
        if ( !username ) { setMessage( 'Ingresá tu usuario' ); status.setErrored(); return; };
        if ( !password ) { setMessage( 'Ingresá tu contraseña' ); status.setErrored(); return; };

        status.setLoading();
        const service = AuthenticationService.get();
        const session = await service.login( username, password );
        if ( session )
        {
            await CredentialProvider.setSession( session );
            status.setOk();

            const from = location.state?.from?.pathname || "/";
            navigate( from, { replace: true } );

        } else 
        {
            setPassword( '' );
            setMessage( service.LastErrorMessage || 'Ocurrió un error. Intentá de nuevo en unos minutos.' );
            status.setErrored();
        }
    }

    return {
        status,
        login,
        username,
        setUsername,
        password,
        setPassword,
        message
    };

}