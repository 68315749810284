import { Col, Colors, Content, Label, Row, Spacer } from '@gower/react-ui/components';
import { MdList, MdOutlineCalculate, RiBillLine } from '@gower/react-ui/icons';
import { Redirect } from '@gower/react-ui/utils';
import { RoutesEnum } from 'app/routes';
import { provider } from './store';
import { MessagesDesktop, MessagesMobile } from './components/messages';
import { ResponsiveView } from '@gower/react-ui/template/responsive-view';

export const HomeView = provider( () => {

    return (
        <ResponsiveView
            desktop={HomeDesktopTemplate}
            mobile={HomeMobileTemplate}
        />
    );

}, { viewName: 'HomeView' } );

export const HomeDesktopTemplate = () => {

    return <>
        <Content
            columns
            padding={50}
            scrollOnlyVertical
        >
            <Col fullWidth maxWidth={1000} center>
                <Row rows noWrap fullWidth>
                    <Col gutter cols={4}>
                        <HomeButton
                            label="Historial de Liquidaciones"
                            icon={<MdList size={24} color={Colors['white']} />}
                            action={() => Redirect.go( RoutesEnum.Payouts )}
                        />
                    </Col>
                    <Col gutter cols={4}>
                        <HomeButton
                            label="Prestaciones facturadas"
                            icon={<RiBillLine size={24} color={Colors['white']} />}
                            action={() => Redirect.go( RoutesEnum.PayoutInvoices )}
                        />
                    </Col>
                    <Col gutter cols={4}>
                        <HomeButton
                            label="Valores de prestaciones"
                            icon={<MdOutlineCalculate size={24} color={Colors['white']} />}
                            action={() => Redirect.go( RoutesEnum.Calculator )}
                        />
                    </Col>
                </Row>
                <Col gutter paddingY={24} fullWidth>
                    <MessagesDesktop />
                </Col>
            </Col>
        </Content>
    </>;
};

const HomeMobileTemplate = () => {

    return <>
        <Content
            columns
            padding={undefined}
            paddingY={24}
            paddingX={12}
            scrollOnlyVertical
            gap={24}
        >
            <Col>
                <HomeButton
                    label="Historial de Liquidaciones"
                    icon={<MdList size={24} color={Colors['white']} />}
                    action={() => Redirect.go( RoutesEnum.Payouts )}
                />
            </Col>
            <Col  >
                <HomeButton
                    label="Prestaciones facturadas"
                    icon={<RiBillLine size={24} color={Colors['white']} />}
                    action={() => Redirect.go( RoutesEnum.PayoutInvoices )}
                />
            </Col>
            <Col  >
                <HomeButton
                    label="Valores de prestaciones"
                    icon={<MdOutlineCalculate size={24} color={Colors['white']} />}
                    action={() => Redirect.go( RoutesEnum.Calculator )}
                />
            </Col>
            <Col>
                <MessagesMobile />
            </Col>
        </Content >
    </>;
};


const HomeButton = ( { label, icon, action } ) => (
    <Row
        rows
        noWrap
        color="primary"
        pointer
        effect="active-transform"
        hoverColor="blue-4"
        padding={24}
        gap={24}
        noSelect
        onClick={action}
    >
        <Col center>
            {icon}
        </Col>
        <Spacer center textCenter>
            <Label textWhite bold normal label={label} />
        </Spacer>
    </Row>
);
